import React from "react"
import { Link, graphql } from "gatsby"
import Container from "../components/Container";
import Quote from "../components/Quote";

export default ({ data }) => {
    const refStyle = {
        padding: '10px'
    }
    console.log(data);
    return (
        <Container>
            <Link to="/">Back</Link>
            <Quote>{data.quote.content} <div style={refStyle}>{data.quote.reference}</div></Quote>
            
        </Container>

    )
}
export const query = graphql`
    query($id: String!) {
        quote(id: {eq: $id}) {
            content
            id
            reference
            title
        }
    }
`